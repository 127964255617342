<template>
  <v-row class="ticket">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/tickets" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>{{ data.number }}
          </router-link>
        </div>
        <div class="text-right">
          <v-btn v-if="data.can_accept" @click="action('accept')" class="white--text ml-5" width="184" height="44" color="secondary">
            Accept
          </v-btn>
          <v-btn v-if="data.can_close" @click="action('close')" class="white--text ml-5" width="184" height="44" color="secondary">
            Close
          </v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card
        v-else
        elevation="0"
        color="transparent"
        max-width="1070"
        class="mx-lg-auto mx-4 mx-md-15 d-flex flex-md-nowrap flex-wrap justify-space-between"
      >
        <v-card class="overflow-y-auto mx-md-0 mx-auto" elevation="5" max-width="760" :width="'100%'" height="'calc(100vh - 176px)'">
          <div class="px-10 py-2 d-flex flex-wrap justify-space-between align-center">
            <v-list color="white" class="pa-0">
              <v-list-item class="pl-0" @click="modal = true">
                <v-list-item-avatar>
                  <v-img v-if="ticketUser.picture" width="40" :src="ticketUser.picture"></v-img>
                  <div v-else>
                    <v-icon>mdi-account mdi-36px</v-icon>
                  </div>
                </v-list-item-avatar>
                <div class="d-flex flex-column">
                  <v-list-item-title v-if="data.account" class="user-role clip font-weight-bold">{{ data.account.name }}</v-list-item-title>
                  <v-list-item-title v-else class="user-role clip font-weight-bold">Wait...</v-list-item-title>
                  <v-list-item-title class="align-self-start font-weight-medium text-capitalize opasity--text">
                    {{ new Date(data.updated_at).toLocaleString() }}
                  </v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
            <div class="d-flex align-center">
              <div class="f18 font-weight-bold">{{ data.topic.title }}</div>
              <v-divider class="mx-4" vertical></v-divider>
              <div class="status" :class="getColorClass(data.status.name)">
                {{ data.status.title }}
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <div
            class="overflow-y-auto px-sm-10 py-sm-5 pa-2"
            :style="`height: calc(100vh - ${data.can_update ? '330' : '250'}px)`"
            id="window"
          >
            <div v-for="item in msgList" :key="item.id">
              <div v-if="item.type == 'system'" class="text-center f14 opasity--text font-weight-medium">{{ item.text }}</div>
              <div style="width: max-content" v-else-if="item.media" class="mt-3" :class="item.staff ? 'ml-auto' : ''">
                <img
                  class="d-block msg-img rounded-t-xl"
                  :class="item.staff ? 'rounded-bl-xl' : 'rounded-br-xl'"
                  :src="item.media.url"
                  alt="image"
                />
              </div>
              <div
                v-else
                class="mt-3 rounded-b-xl py-2 px-4 f18"
                style="width: max-content; max-width: 55%"
                :class="item.staff ? 'rounded-tl-xl accent ml-auto' : 'primary white--text rounded-tr-xl'"
              >
                {{ item.text }}
              </div>
              <div v-if="item.type != 'system'" class="f14 opasity--text" :class="item.staff ? 'ml-auto' : ''" style="width: max-content">
                {{ new Date(item.created_at).toLocaleString() }}
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <div v-if="data.can_update" class="accent px-sm-10 px-2 py-1 d-flex flex-wrap align-center">
            <v-btn @click="changeFile" elevation="0" color="input" width="40" height="40" dark><v-icon>mdi-plus</v-icon></v-btn>
            <v-text-field
              v-model="msg"
              placeholder="Type a message..."
              :error-messages="msgErrors"
              class="mx-sm-10 mx-3 mt-4"
              clearable
              @click:clear="clearMsg"
              dense
              height="38"
              required
              color="input"
              v-if="!file"
            >
            </v-text-field>
            <div v-else style="height: 72px" class="d-flex align-center mx-sm-10 mx-3">
              <v-chip close @click:close="(file = ''), (msg = '')" color="accent" text-color="opasity">{{ media }}</v-chip>
            </div>
            <v-spacer v-if="file"></v-spacer>
            <v-btn @click="send" elevation="0" color="input" width="40" height="40" dark><v-icon>mdi-send</v-icon></v-btn>
          </div>
        </v-card>
        <v-card
          class="overflow-y-auto ml-md-4 mx-md-0 mx-auto mt-md-0 mt-5"
          elevation="5"
          max-width="290"
          :width="'100%'"
          :height="`calc(100vh - 176px)`"
        >
          <div class="pa-4">
            <v-tabs centered active-class="input white--text">
              <v-tabs-slider color="transparent"></v-tabs-slider>
              <v-tab class="text-capitalize text-body-1 tab rounded-l">My tickets</v-tab>
              <v-tab class="text-capitalize text-body-1 tab rounded-r">
                Incoming <span class="d-inline-block text-caption error white--text indicator">{{ ticketAllTwo.length }}</span>
              </v-tab>
              <v-tab-item>
                <v-card flat v-for="item in ticketAll" :key="item.id" class="my-2" @click="toPage(item.id)">
                  <div class="d-flex justify-space-between">
                    <div class="font-weight-medium">{{ item.number }}</div>
                    <div class="f14 opasity--text">{{ new Date(item.updated_at).toLocaleString() }}</div>
                  </div>
                  <div class="opasity--text">{{ item.account.name }}</div>
                  <v-divider class="mt-1"></v-divider>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat v-for="item in ticketAllTwo" :key="item.id" class="my-2" @click="toPage(item.id)">
                  <div class="d-flex justify-space-between">
                    <div class="font-weight-medium">{{ item.number }}</div>
                    <div class="f14 opasity--text">{{ new Date(item.updated_at).toLocaleString() }}</div>
                  </div>
                  <div class="opasity--text">{{ item.account.name }}</div>
                  <v-divider class="mt-1"></v-divider>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card>
      </v-card>
      <v-dialog v-model="modal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="d-flex flex-wrap align-center mb-5">
            <v-avatar v-if="ticketUser.picture" size="128">
              <v-img :src="ticketUser.picture"></v-img>
            </v-avatar>
            <v-avatar v-else color="gray" size="128">
              <v-icon>mdi-account</v-icon>
            </v-avatar>
            <div class="ml-5">
              <div class="text-h5 font-weight-bold mb-4">{{ ticketUser.name }}</div>
              <v-btn :to="`/accounts/${ticketUser.id}`" color="primary" elevation="0" height="48" width="120">Account</v-btn>
            </div>
          </div>
          <div class="font-weight-medium">Balance</div>
          <div class="opasity--text">{{ ticketUser.balance }}</div>
          <div class="font-weight-medium mt-3">Email</div>
          <div class="opasity--text">{{ ticketUser.main_email }}</div>
          <div class="font-weight-medium mt-3">
            Moderation
            <span class="white--text status" :class="ticketUser.moderation ? 'success' : 'error'">
              {{ ticketUser.moderation ? 'On' : 'Off' }}
            </span>
          </div>
          <div class="font-weight-medium mt-3">Payment mode</div>
          <div class="opasity--text">{{ ticketUser.payment_mode.title }}</div>
          <div class="font-weight-medium mt-3">Billing period</div>
          <div class="opasity--text">{{ ticketUser.billing_period.title }}</div>
          <div class="font-weight-medium mt-3">Billing delay days</div>
          <div class="opasity--text">{{ ticketUser.billing_delay }}</div>
          <v-divider class="my-5"></v-divider>
          <div class="text-center">
            <v-btn @click="modal = false" width="120" height="48" color="white">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    msg: { required },
  },
  data() {
    return {
      load: true,
      msg: '',
      file: '',
      media: '',
      modal: false,
    };
  },
  mounted() {
    this.getData();
    this.processTab();
    this.sumbitTab();
  },
  methods: {
    getColorClass(block) {
      if (block == 'submitted') {
        return 'yellow darken-2';
      } else if (block == 'closed') {
        return 'error';
      } else if (block == 'processing') {
        return 'success';
      } else if (block == 'reopened') {
        return 'input';
      }
    },
    async getData() {
      await this.$store.dispatch('getTicket')
      this.$store.dispatch('getTicketMsg');
      this.$store.dispatch('getTicketAccount').finally(() => {
        this.load = false;
      });
      this.$store.dispatch('timerTicket');
    },
    async action(type) {
      const data = new Object();
      data.action = type;
      await this.$store.dispatch('actionTicket', data);
    },
    async send() {
      this.$v.msg.$touch();
      if (!this.$v.msg.$invalid) {
        const formData = new FormData();
        if (this.file) {
          formData.append('picture', this.file);
          formData.append('type', 'media');
        } else {
          formData.append('text', this.msg);
          formData.append('type', 'text');
        }
        await this.$store.dispatch('sendTicketMsg', formData).then(() => {
          this.clearMsg();
        });
      }
    },
    clearMsg() {
      this.msg = '';
      this.$v.msg.$reset();
    },
    changeFile() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.msg = self.file.name;
            self.media = self.file.name;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    bottomChat() {
      const block = document.getElementById('window');
      block.scrollTo(0, block.scrollHeight || 0);
    },
    toPage(id) {
      this.$route.params.ticketId != id ? this.$router.push({ name: 'Ticket', params: { ticketId: id } }) : '';
    },
    async processTab() {
      await this.$store.dispatch('getTicketAll', 'processing');
    },
    async sumbitTab() {
      await this.$store.dispatch('getTicketAllTwo', 'submitted');
    },
  },
  watch: {
    msgList() {
      setTimeout(() => {
        this.bottomChat();
      }, 100);
    },
    newTicket() {
      this.$store.dispatch('closeWebSocketTicket');
      this.getData();
    },
  },
  computed: {
    data() {
      return this.$store.getters.ticket;
    },
    ticketUser() {
      return this.$store.getters.ticketAccount;
    },
    ticketAll() {
      return this.$store.getters.ticketAll;
    },
    ticketAllTwo() {
      return this.$store.getters.ticketAllTwo;
    },
    msgList() {
      return this.$store.getters.ticketMsg;
    },
    newTicket() {
      return this.$route.params.ticketId;
    },
    msgErrors() {
      const errors = [];
      if (!this.$v.msg.$dirty) {
        return errors;
      }
      !this.$v.msg.required && errors.push('Please enter your message');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setTicket', { topic: {}, staff: {}, status: {}, account: {} });
    this.$store.dispatch('setTicketAll', []);
    this.$store.dispatch('setTicketMsg', []);
    this.$store.dispatch('closeWebSocketTicket');
    this.$store.dispatch('setTicketAccount', { payment_mode: {}, status: {}, moderation: {}, billing_period: {} });
  },
};
</script>

<style lang="scss">
.ticket {
  .status {
    width: max-content;
    height: 24px;
    font-size: 14px;
    padding: 0 20px;
    border-radius: 24px;
    color: white;
    line-height: 24px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 40px !important;
  }
  .msg-img {
    height: 200px;
  }
  .tab {
    border: 1px solid rgb(206, 206, 206);
    height: 42px;
  }
  .indicator {
    line-height: 18px;
    padding: 0 10px;
    border-radius: 24px;
    margin-left: 5px;
  }
  @media screen and (max-width: 599px) {
    .msg-img {
      height: 150px;
    }
  }
}
.v-dialog {
  .v-avatar .v-icon {
    font-size: 112px;
  }
  .status {
    margin-left: 20px;
    display: inline-block;
    width: max-content;
    height: 26px;
    font-size: 14px;
    padding: 0 20px;
    border-radius: 24px;
    line-height: 26px;
  }
}
</style>
